<template>
  <section>
    <v-progress-linear v-if="loading"
      indeterminate
      class="global-loader"
    ></v-progress-linear>
    <v-container fluid v-if="!loading">
      <validation-observer ref="form" v-slot="{ validate }">
        <v-form @submit.prevent="submit" class="mb-16">         
          <fieldset :disabled="loading">
            <v-tabs dark v-model="tab" fixed-tabs>
              <v-tab v-for="language in languages" :key="language.id">{{ language.language }}</v-tab>
            </v-tabs>
            <v-row class="mt-4">
              <v-col cols="12" v-if="!canSave">
                <v-alert
                  outlined
                  type="error"
                  border="left"
                >
                  É necessário preencher a informação relativa a todos os idiomas. Em falta: {{ getLanguageMissing() }}
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="reference" name="Referência" rules="required">
                  <v-text-field
                    v-model="fields.reference"
                    prepend-inner-icon="mdi-account-details"
                    :label="$t('globals.reference') + '*'"
                    dense
                    outlined
                    class="mt-4"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="language in languages" :key="language.language">
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="'name_' + language.language"
                      :name="$t('globals.name') + language.language"
                      rules="required"
                    >
                      <v-text-field
                        v-model="fields.i18n[language.language]"
                        prepend-inner-icon="mdi-account-details"
                        :label="$t('globals.name') + '*'"
                        dense
                        outlined
                        class="mt-4"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-btn :disabled="!canSave || !validate" depressed color="primary" type="submit">
                {{ $t('globals.save') }}
              </v-btn>
            </v-row>
          </fieldset>
        </v-form>
        <DialogSuccess :opened="success" @on-ok="onSuccess">
          {{ $t('department.success') }}
        </DialogSuccess>
      </validation-observer>
    </v-container>
  </section>
</template>

<script>
import Department from "@/api/Department.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Languages from "@/api/Languages.js";

export default {
  components: { 
    DialogSuccess
  },
  props: {
    department: Object,
    method: String,
    disabled: Boolean,
  },
  watch: { 
    department: {
      immediate: true, 
      handler(val) {
        if (!val) {
          return;
        }
        this.fields = { ...val };
        this.fields.id = val.id;
      }
    },
  },
  data: () => ({
    tab: 0,
    languages: [],
    fields: {
      id: null,
      reference: null,
      i18n: {}
    },
    loading: true,
    error: {
      title: '',
      message: '',
    },
    success: false,
  }),
  mounted() {
    this.fillBaseData();
  },
  computed: {
    canSave() {
      return this.languages.every(lang => !!this.fields.i18n[lang.language]);
    }
  },
  methods: {
    getLanguageMissing() {
      return this.languages
        .filter(lang => !this.fields.i18n[lang.language])
        .map(lang => lang.language)
        .join(', ');
    },
    onSuccess() {
      if (this.fields.id !== null) {
        this.success = false;
        return;
      }
      this.$router.push(`/${this.$session.get('domain')}/admin/settings/departments`);
    },
    async fillBaseData() {
      try {
        const { data } = await Languages.list();
        this.languages = data;

        if (this.method === 'create') {
          const response = await Department.nextCode();
          this.fields.reference = response.data;
        }
      } catch (error) {
        console.error("Erro ao carregar dados iniciais:", error);
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }


      try {
        const data = { ...this.fields };
        await Department[this.method](data, this.fields.id);

        this.success = true;
      } catch (err) {
        if (err.response?.status === 422) {
          const errors = err.response.data.errors;

          Object.keys(errors).forEach(field => {
            const errorMessage = errors[field].join(', ');

            if (field === 'reference') {
              this.$refs.form.setErrors({ reference: errorMessage });
            } else {
              // Handle dynamic i18n errors
              const languageKey = field.split('.').pop();
              this.$refs.form.setErrors({ [`name_${languageKey}`]: errorMessage });
            }
          });
        } else {
          this.error.title = `Erro ${err.response?.status || ''}`;
          this.error.message = err.response?.data?.message || 'Ocorreu um erro.';
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
