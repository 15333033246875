<template>
    <section class="mt-16">
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
            <v-icon color="primary">mdi-wrench</v-icon> <span>{{$t('account.title')}}</span>
        </h1>
        <div class="mt-4" style="position:relative" v-if="!loading">
            <v-container fluid>
                <v-card>
                    <v-card-title class="primary white--text">
                        {{$t('account.registry_data')}}
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.company_name')"
                                    v-model="company.name"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.company_address')"
                                    v-model="company.address"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.postal_code')"
                                    v-model="company.postal_code"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.local')"
                                    v-model="company.local"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.country')"
                                    v-model="company.country"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    disabled
                                    :label="$t('globals.email')"
                                    v-model="company.email"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field
                                    disabled
                                    :label="$t('globals.telephone')"
                                    v-model="company.telephone"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field
                                    disabled
                                    :label="$t('subscriptions.register_view.nif')"
                                    v-model="company.nif"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    disabled
                                    :label="$t('globals.domain')"
                                    v-model="company.domain"
                                    dense outlined
                                />
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-text-field
                                    disabled
                                    :label="'Link:'"
                                    :value="'https://' + company.link"
                                    dense outlined
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-title class="primary white--text">
                        {{$t('account.select_language')}}
                    </v-card-title>
                    <v-card-text>                                
                        <AvailableLanguages />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" align="end">
                                <v-btn
                                    outlined
                                    color="red"
                                    @click="show = !show"
                                >
                                    {{$t('account.delete')}}<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>                        
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="show">
                            <v-divider></v-divider>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <v-alert
                                            text
                                            outlined
                                            color="deep-orange"
                                            icon="mdi-alert"
                                            >
                                            {{$t('account.delete_warning')}}
                                        </v-alert>
                                        
                                    </v-col>
                                    <v-col cols="12" align="center">                                        
                                        <v-btn color="error" @click="deletePermanentAccount">
                                            <v-icon>mdi-trash-can</v-icon>
                                            {{$t('globals.delete')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>                                
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-container>
        </div>
        <ConfirmDeleteAccount v-if="deleteAccountDialog" @close="deleteAccountDialog = false"/>

    </section>
</template>
<script>
import ConfirmDeleteAccount from '@/views/settings/whitelabeling/DeleteAccount/ConfirmDeleteAccount.vue'
import AvailableLanguages from '@/components/ui/AvailableLanguages.vue'
import Branding from "@/api/Branding.js"

export default{
    name:"Account",
    components:{
        ConfirmDeleteAccount,
        AvailableLanguages
    },
    data(){
        return{
            loading: true,
            company: null,
            show: false,
            deleteAccountDialog: false
        }
    },
    mounted(){
        if(!this.$root.session.hasPermission(["super"])) {
            this.$router.push('/' + this.$session.get('domain') + '/no-permission');
            return
        }
        
        this.company = this.$store.tenant_company

        this.loading = false
    },
    methods:{      
        deletePermanentAccount(){
            Branding.deletePermanent().then(() =>   {
                this.deleteAccountDialog = true
            })
        },
    },
    computed:{
        crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.definitions'),
            disabled: true,
            exact: true,
          },
          {
            text: this.$t('menu.account'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
}
</script>