<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>{{$t('department.add_crumb')}}</span>
        </h1>
  
        <div class="mt-16">
          <department method="create"></department>
        </div>
    </section>
  </template>
  <script>
  import Department from '@/components/departments/Department'
  
  export default {
    components: {
        Department
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "departments.create"])) {
        this.$router.push('/' + this.$session.get('domain') + '/no-permission');
        return
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.definitions'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/settings/departments',
            exact: true,
          },
          {
            text: this.$t('department.add_crumb'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  