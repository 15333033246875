<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-tabs v-model="tab">
            <v-tab v-for="language in languages" :key="language.id">{{language.language}}</v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="reference" name="Referência" rules="required">
                <v-text-field
                  v-model="fields.reference"
                  prepend-inner-icon="mdi-account-details"
                  :label="$t('globals.reference') +  '*'"
                  dense outlined
                  class="mt-4"
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="language in languages" :key="language.language">
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider v-slot="{ errors }" :vid="'name' + language.language" name="Nome" rules="required">
                      <v-text-field
                        v-model="fields.i18n[language.language]"
                        prepend-inner-icon="mdi-account-details"
                        :label="$t('globals.name') + '*'"
                        dense outlined
                        class="mt-4"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-tab-item>
          </v-tabs-items>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" type="submit">
              {{$t('globals.save')}}
            </v-btn>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        {{$t('category.success')}}
      </DialogSuccess>
    </validation-observer>
  </template>
  <script>
  import Category from "@/api/Category.js";
  import Languages from "@/api/Languages.js"
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: { 
      DialogSuccess
    },
    props: {
      category: Object,
      method: String,
      disabled: Boolean,
   },
   watch: { 
    category: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    data: () => ({
      tab: null,
      languages:[],
      fields: {
        id: null,
        reference: null,
        i18n: {}
      },
      loading:true,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
      this.fillBaseData();
    },
    methods:{
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/' + this.$session.get('domain') + '/admin/settings/categories');
      },
      async fillBaseData(){
        await Languages.list().then(({data}) => {
          this.languages = data;
        });
        this.loading = false
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          Category[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    }
  };
  </script>
  