<template>
  <v-app>
    <v-main>
      <v-container fill-height class="justify-center">

            <v-card v-if="!error" class="rounded-xl elevation-6" outlined width="400" min-height="400">
              <v-card-title v-if="changed == false" class="text-center justify-center text-h4 font-weight-bold">
                {{$t('login.reset_password')}}
              </v-card-title>
              <v-card-text v-if="changed == false">
                <validation-observer
                  ref="password"
                  v-slot="{ invalid }"
                >
                  <v-form @submit.prevent="submit" v-if="!error">
                    <v-row>
                      <v-col
                          cols="12"
                          md="12"
                        >
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider name="Password" vid="password" rules="required|password_size|special_char_1|capital_letter|small_letter|one_number" v-slot="{ errors }">
                              <v-text-field v-model="password" dense outlined label="Password *" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <validation-provider name="Confirmação Password" vid="password_confirmation" rules="required|password:@password" v-slot="{ errors }">
                              <v-text-field v-model="password_confirmation" dense outlined :label="$t('login.confirm') + ' *'" type="password" prepend-inner-icon="mdi-form-textbox-password" :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                        <v-btn
                          depressed
                          color="primary"
                          class="mr-4"
                          type="submit"
                          :disabled="invalid"
                          large
                          block
                        >
                          {{$t('login.change')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row v-else>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="error--text text-center">{{$t('login.cant_change')}}</h2>
                      <p class="body-1 text-center mt-5">{{$t('login.in_touch')}}: <a class="text-decoration-none font-weight-bold secundary--text" :href="'mailto:' + email">{{email}}</a></p>

                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="6"
                          offset-md="3"
                        >
                          <v-btn
                            depressed
                            color="primary"
                            class="mr-4"
                            type="button"
                            large
                            block
                            link
                            to="/"
                          >
                            Homepage
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </validation-observer>
              </v-card-text>

              <v-card-title v-if="changed" class="text-center justify-center text-h2 font-weight-bold">
                {{$t('login.thanks')}}
              </v-card-title>
              <v-card-text v-if="changed">
                <v-row class="py-12">
                  <v-col cols="12" align="center">
                    <span class="text-h6">{{$t('login.success_change')}}</span>
                  </v-col>
                  <v-col cols="12" align="center">
                    <v-btn
                      depressed
                      color="primary"
                      class="text-white"
                      @click="$router.push('/')"                      
                      rounded
                    >
                      {{$t('login.start')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-center mb-4">
                <v-img 
                      style="display: block; margin: auto;"
                      src="@/assets/icons/logo_preto.jpg" 
                      max-width="150"/>
              </v-card-actions>
            </v-card>

          
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import User from "@/api/User.js"

export default {
  components: {
  },
  data: () => ({
    password: "",
    changed: false,
    password_confirmation: "",
    error: false,
  }),
  beforeCreate(){

    document.title = this.$t('app_name');

    if(localStorage['auth'] == 1){
      this.$router.push('/admin');
    }
  },
  computed: {
    email: function(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods:{
    submit () {
        this.$refs.password.validate().then((valid) => {
          if (!valid) {
            return;
          }

          let payload = {
            id: this.$route.params.id,
            password: this.$encryptValue(this.password),
            password_confirmation: this.$encryptValue(this.password_confirmation),
          }

          User.updatePassword(payload)
            .then(() => {

                this.changed = true

                
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.password.setErrors(err.response.data.errors);
                
                return;
              }

              this.error = true;

          });
        });
      },
  }
};
</script>
