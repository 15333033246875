<template>
  <v-theme-provider root>
    <v-app>
        
      
        <v-navigation-drawer
          class="text-white"
          :color="getComputedColor"
          style="z-index: 100;"
          app
          :mini-variant="showMini"
          v-model="showMenu"
          width="300"
        >
        <v-list nav
        >
        <v-badge
        v-if="false"
          bordered
          @click.native="openMenu"
          color="error"
          :icon="!showMini ? 'mdi-arrow-expand-left' : 'mdi-arrow-expand-right'"
          overlap 
          tile
          class="d-none d-lg-block mt-0 mr-2"
          style="position: absolute; right: 0; z-index: 9999;"
        >
        </v-badge>
          <v-container class="fill-height fill-width" fluid 
              v-if="!showMini">
              <v-img 
                src="@/assets/icons/logo_branco_semslogan.png"
                :aspect-ratio="16/9"
                style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      zoom: 65%;"
                contain
                max-height="100px"
                width="100%"
              />
          </v-container>
          <v-container class="fill-height fill-width" fluid 
              v-else>
              <v-avatar
                size="35px"
                class="ml-n2 mt-2"
              >
                <img
                  alt="Avatar"
                  src="@/assets/icone_branco.png"
                >
              </v-avatar>  
          </v-container>
          <v-divider class="mb-2" color="white" v-if="logo_url != null"></v-divider>
            <v-row>
              <v-col cols="12">              
                <div style="color: white !important;" :class="!showMini ? 'text-center mb-n4' : 'text-center mb-n2 ml-n2'">
                  
                    <p class="mt-n6"><br/><span v-if="!showMini" class="text-overline" style="font-size:8px !important;">({{ this.$root.session == undefined ? '' : this.$root.session.email }})</span></p>
                </div>
              </v-col>
              <v-col cols="12" class="text-center mb-4 mt-n4">
                <v-btn icon
                  @click="seeNotification">
                <v-badge  
                  style="cursor: pointer;" 
                  overlap                
                  v-if="notifications_to_read.length > 0"
                  color="#DAA520"
                  :content="notifications_to_read.length"
                >
                  <v-icon large color="warning">mdi-bell</v-icon>
                </v-badge>
                <v-icon 
                  style="cursor: pointer;" large v-else color="white">mdi-bell</v-icon>
              </v-btn>
              </v-col>
              
            </v-row>
  
          <v-divider color="white"></v-divider>
          <div v-if="!lockMenu">
            <template v-if="loading">
              <v-skeleton-loader
                v-for="i in items.length"
                v-bind:key="i"
                type="list-item"
              ></v-skeleton-loader>
            </template>
            <template v-for="item in items" v-else>
                  <v-list-item :style="'background-color: ' + getColor(item) + ';'" link :to="item.link" :exact="item.exact" :key="item.title" v-if="item.items == 0">
                      <v-list-item-icon>
                        <v-icon >{{item.icon}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title style="color: white">{{ item.title }}</v-list-item-title>
                  </v-list-item> 
                  <v-list-group :style="'background-color: ' + getColor(item) + ';'" :group="item.group" :key="item.title" v-else>
                    <template v-slot:activator>
                      <v-list-item-icon>
                          <v-icon >{{item.icon}}</v-icon>
                        </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" style="color: white"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    
                    <template v-for="child in item.items">
                          <v-list-item :style="'background-color: ' + getColor(child) + ';'" link :to="child.link" :exact="child.exact" :key="child.title" v-if="child.has_permission">
                            <v-list-item-icon>
                              <v-icon >{{child.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="child.title" style="color: white"></v-list-item-title>
                        </v-list-item> 
                        </template>
                  </v-list-group> 

              
            </template>

            <v-divider color="white"></v-divider>
            <v-list-item :style="'background-color: ' + getColor({color: 'secondary'}) + ';'" class="fixedBottom" :to="'/' + $session.get('domain') + '/admin/tutorial'">
                  <v-list-item-icon>
                    <v-icon>mdi-help</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title style="color: white;">Tutorial</v-list-item-title>
              </v-list-item> 
          </div>
        </v-list>
  
  
  
        
        
        <template v-slot:append>
          <div v-if="!loading">
          <v-list>
            <v-divider color="white" v-if="showMini"></v-divider>
            <!-- REMOÇAO DA LINGUA, SO PORTUGUES PARA JA -->
            <v-list-item>             
              <v-speed-dial 
                v-model="fab"
                :direction="'top'"
                :open-on-hover="true"
                :transition="'slide-y-reverse-transition'"
              >
                <template v-slot:activator>
                  <flag :iso="computeLocale" />
                </template>
                <v-btn
                  v-for="language in languages"
                  :key="language.language"
                  fab
                  dark
                  small
                  color="white"
                  @click="changeLanguage(language.language)"
                >
                <flag :iso="getIsoLanguage(language.language)"/>
                </v-btn>
              </v-speed-dial>           
                <v-btn v-if="!showMini" @click="logout" icon class="mr-2" style="right: 0; position: absolute;">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-list-item>
            
            <v-list-item v-if="showMini" :style="'background-color: ' + getColor({color: 'primary'}) + ';'">            
                <v-btn @click="logout" icon class="ml-2" style="left: 0; position: absolute;">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-list-item>
  
  
          </v-list>
        </div>
        </template>
          
        
  
      </v-navigation-drawer>
  
      
      <v-main>
        <v-btn
            class="mt-2 ml-1 floating-button primary"
            style="z-index: 99;"
            @click="openMenu"
            dark
            top
            left
            fab
          >
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </v-btn>
        <v-progress-linear v-if="loading"
        indeterminate
        class="global-loader"
      ></v-progress-linear>
        <v-container v-on="$listeners" fluid v-if="!loading">
            <v-row class="pa-0 ma-0">
                <v-col offset="0" cols="12" offset-lg="1" lg="10">
                    <router-view></router-view>
                </v-col>
            </v-row>
        </v-container>
      </v-main>
      <v-footer padless>
     
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong :class="this.$root.$vuetify.theme.isDark ? 'white--text' : 'primary--text'">Ignoto V{{getVersion()}}</strong>
        </v-col>
      </v-footer>
    </v-app>
  </v-theme-provider>
  </template>
  <script>
  import User from "@/api/User.js"
  import Languages from "@/api/Languages.js"
  import Configuration from '@/api/Configuration.js'
  import Notifications from '@/api/Notification.js'
  import TenantCompany from '@/api/TenantCompany.js'

  export default {
    components: {
      
    },
    watch: {
      showMenu: {
        immediate: false,
        handler (val) {
           localStorage['menu-show'] = val;
        },
      },
      showMini: {
        immediate: false,
        handler (val) {
           localStorage['menu-mini'] = val;
        },
      },
      $route: {
          immediate: true,
          handler() {
            if(this.$root.session == undefined){
              return
            }
  
            let theme = this.$root.session.theme;
  
            theme = theme.length == 0 ? this.$themes[0] : theme
            
            this.setTheme(theme);
  
            this.setCssVars();
          }
      },
    },
    data: () => ({
        lockMenu: false,
        fab: false,
        dialog: true,
        logo_url: null,
        showMenu: false,
        languages: [],
        showMini: false,
        isRender: true,
        user: {},
        items: [],
        notifications: [],
        notifications_to_read: [],
        loading: true,
        groupSelectedColor: {},
        admins: [
          ['Management', 'mdi-account-multiple-outline'],
          ['Settings', 'mdi-cog-outline'],
        ],
        cruds: [
          ['Create', 'mdi-plus-outline'],
          ['Read', 'mdi-file-outline'],
          ['Update', 'mdi-update'],
          ['Delete', 'mdi-delete'],
        ],
        applicationData:{
          coin: '€'
        }

    }),
    mounted(){
      
    },
    async beforeMount(){
      this.$vuetify.theme.dark = +localStorage['dark-mode'];

      
      /*localStorage['auth'] = 0;
      this.logout()
      let a = true
      if(a)
      return*/
        
      await User.getSession(false).then(async ({data}) =>  {
        this.user = User.getInstance(data);
  
        let theme = data.theme;
  
        theme = theme.length == 0 ? this.$themes[0] : theme
  
        this.setTheme(theme);
  
        this.$root.session = this.user;
        this.$session.start()
        this.$session.set('domain', data.domain)

        if(this.$session.get('domain') == null || this.$session.get('domain') == 'null'){
          this.logout()
          return
        }

        await this.loadConfigurations()

        this.fillBaseData()
  
  
        if(!location.pathname.includes(data.domain)){
          if(!location.pathname.includes('my-user')){
            this.$router.push(data.domain + '/admin')
          }
        }
  
        localStorage['auth'] = 1;
  
        this.loading = false;
  
        this.setCssVars();
  
      }).catch((error) => {
  
        console.error(error);
  
        localStorage['auth'] = 0;
  
        this.$router.push('/');
      });
  
      
  
      this.showMini = localStorage['menu-mini'] == "true";
  
      let showMenu = localStorage['menu-show'];
  
      this.showMenu = showMenu != undefined ? showMenu == "true" : true;
  
      this.showMenu = this.showMenu || !this.$vuetify.breakpoint.mdAndDown;
    },
    computed:{ 
      computeLocale(){
        if(this.$i18n.locale == 'en')
          return 'gb'

        return this.$i18n.locale
      },
      getComputedColor(){
        return this.$root.$vuetify.theme.isDark ? this.$root.$vuetify.theme.themes.dark.primary : this.$root.$vuetify.theme.themes.light.primary
      },
      getEmail(){
        if(this.$root.session == undefined)
          return ''
        return this.$root.session.email
      },
      getUsername(){
        if(this.$root.session == undefined)
          return ''
        return this.$root.session.username
      }
    },
    methods:{
      seeNotification(){
        this.$router.push('/' + this.$session.get('domain') + '/admin/notifications')
      },
      async loadConfigurations(){
        await Configuration.list(null, this.$session.get('domain')).then((resp) => {
       
          let data = resp.data.data
          
          this.configuration = Configuration.getInstance(data)

          this.$root.configurations = this.configuration

          this.$store.configurations = resp.data

          this.setMenu();

        }).catch((error) => {

          console.error(error);

          localStorage['auth'] = 0;

          this.$router.push('/');
        });

        await TenantCompany.find(this.$session.get('domain')).then((resp) => {
          this.$store.tenant_company = resp.data.tenant_company
          this.$store.giveTrial = resp.data.giveTrial
          this.$store.subscription = resp.data.subscription

          //console.log(resp.data)

          this.changeLanguage(resp.data.tenant_company.language)
          
          if(resp.data.subscription == false){
            this.lockMenu = true
            this.$router.push('/' + this.$session.get('domain') + '/invalid-subscription')
            return
          }

          if(resp.data.subscription == null){       
            this.lockMenu = true
            
            this.$router.push('/' + this.$session.get('domain') + '/invalid-subscription')
            return
          }

          if(resp.data.subscription.active == false){       
            this.lockMenu = true
            
            this.$router.push('/' + this.$session.get('domain') + '/invalid-subscription')
            return
          }
        });
      },
      fillBaseData(){
        Languages.list(null, this.$session.get('domain')).then(({data}) => {
          this.languages = data;
        });
        Notifications.list(1, 0, this.$session.get('domain')).then(({data}) => {
          this.notifications = data
          this.notifications.forEach(element => {
            if(element.read_at == null)
              this.notifications_to_read.push(element)
          });
        });
      },
      changeLanguage(l){
        this.$i18n.locale = l
        localStorage["default-language-ignoto-" + this.$root.session.id] = l
        this.setMenu();
      },
      getIsoLanguage(l){
        if(l == 'en')
          return 'gb'

        return l
      },
      getColor(item){
        return this.$root.$vuetify.theme.isDark ? this.$vuetify.theme.themes.dark[item.color] : this.$vuetify.theme.themes.light[item.color]
      },
      checkPermissions(p){
        return this.$root.session.hasPermission(p)
      },
      getVersion(){
              return process.env.VUE_APP_SECRET_VERSION
      },
      setCssVars(){
        let color = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primaryLight
            : this.$vuetify.theme.themes.light.primaryLight;
  
        this.groupSelectedColor = {
          '--group-selected': color,
        };
      },
      toggleTheme(){
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  
        localStorage['dark-mode'] = Number(this.$vuetify.theme.dark);
      },
      logout() {
        User.logout(this.$root.session.id)
          .then(() => {
            localStorage['auth'] = 0;

            // Apaga todos os cookies associados ao site
            document.cookie.split(";").forEach(cookie => {
              const name = cookie.split("=")[0].trim();
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
            });

  
            this.$router.push('/');
          });
      },
      openMenu(){
  
        if(!this.$vuetify.breakpoint.mdAndDown){
  
          this.showMini = !this.showMini;
  
          return;
        }
  
        this.showMini = false;
  
        this.showMenu = !this.showMenu;
      },
      setMenu() {
        let domain = this.$session.get('domain')
        let menu = [{
          title: this.$t('menu.dashboard'),
          icon: "mdi-view-dashboard",
          color: 'secondary',
          has_permission: true,
          exact: true,
          link: "/" + domain + "/admin",
          items:[],
        },  
        {
          title: this.$t('menu.complaints_management'),
              icon: "mdi-file-outline",
              has_permission: this.$root.session.hasPermission(["super", "complaints.read"]),
              link: "/" + domain + "/admin/complaints/list",
              exact: false,
              items:[],
        }, 
        {
          title: this.$t('menu.exports'),
          icon: "mdi-view-dashboard",
          color: 'secondary',
          has_permission: true,
          exact: true,
          link: "/" + domain + "/admin/exports",
          items:[],
        },  
        {
          title: this.$t('menu.white_labeling'),
          icon: "mdi-pencil-ruler",
          has_permission: this.$root.session.hasPermission(["super", "white.labeling"]),
          link: "/" + domain + "/admin/whitelabeling",
          exact: false,
          items:[],
        },
        {
        title: this.$t('menu.definitions'),
        icon: "mdi-cog",
        has_permission: this.$root.session.hasPermission(["super", "roles.read", "users.read", "logs.read"]),
        link: "",
        group:"/" + domain + "/admin/settings",
        exact: false,
        items:[
            {
              title: this.$t('menu.my_profile'),
              icon: "mdi-account",
              has_permission: true,
              link: "/" + domain + "/admin/settings/users/" + this.$root.session.id,
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.account'),
              icon: "mdi-wrench",
              has_permission: this.$root.session.hasPermission(["super"]),
              link: "/" + domain + "/admin/settings/account",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.categories_management'),
              icon: "mdi-shape",
              has_permission: this.$root.session.hasPermission(["super", "categories.read"]),
              link: "/" + domain + "/admin/settings/categories",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.states_management'),
              icon: "mdi-state-machine",
              has_permission: this.$root.session.hasPermission(["super", "states.read"]),
              link: "/" + domain + "/admin/settings/states",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.departments_management'),
              icon: "mdi-home-group",
              has_permission: this.$root.session.hasPermission(["super", "departments.read"]),
              link: "/" + domain + "/admin/settings/departments",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.roles_management'),
              icon: "mdi-shield-account",
              has_permission: this.$root.session.hasPermission(["super", "roles.read"]),
              link: "/" + domain + "/admin/settings/roles",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.users_management'),
              icon: "mdi-account-multiple",
              has_permission: this.$root.session.hasPermission(["super", "users.read"]),
              link: "/" + domain + "/admin/settings/users",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.subscriptions'),
              icon: "mdi-billboard",
              has_permission: this.$root.session.hasPermission(["super", "subscriptions.read"]),
              link: "/" + domain + "/admin/settings/subscriptions",
              exact: false,
              items:[],
            },
            {
              title: this.$t('menu.logs'),
              icon: "mdi-paperclip",
              link: "/" + domain + "/admin/settings/logs",
              has_permission: this.$root.session.hasPermission(["super", "logs.read"]),
              exact: false,
              items:[],
            },
          ]
        } 
      ];
      
        let fixed_array = []
        menu.forEach(element => {
          fixed_array.push(element)
        });
  
        this.items = fixed_array.filter((item) => {
          return item.has_permission;
        })

      }
    },
  };
  </script>
  <style scoped>
    .floating-button{
      position: fixed !important;
      top: 0 !important;
    }
    
    .v-list-group--active{
      background-color: var(--group-selected);
      border-radius: 4px;
    }
    .fixedBottom {
      position: static !important;
      bottom: 0 !important;
      width: 100%;
    }
    .center {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  </style>