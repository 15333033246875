<template>
    <validation-observer ref="form" v-slot="{ invalid }">
        <v-form :disabled="disableForm" @submit.prevent="submit"  class="mb-16"  v-if="!loading">
            <v-container>
                <v-card class="elevation-5">
                    <v-card-title class="primary white--text">
                        {{$t('complaints.case_detail')}}
                        <v-container>
                            <v-speed-dial
                            absolute
                            v-model="speedDial"
                            top
                            right
                            direction="bottom"
                            :open-on-hover="true"
                            >
                            <template v-slot:activator>
                                <v-tooltip right color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        v-bind="attrs" v-on="on"
                                        v-model="speedDial"
                                        class="elevation-0"
                                        color="primary"
                                        x-large
                                        >
                                        <v-icon v-if="speedDial">
                                            mdi-close
                                        </v-icon>
                                        <v-icon v-else>
                                            mdi-dots-vertical
                                        </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('globals.options')}}</span>
                                    </v-tooltip>
                                </template>
                                <v-tooltip right color="success">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        fab
                                        dark
                                        small
                                        color="success"
                                        large
                                        @click="exportComplaint()"
                                    >
                                        <v-icon>mdi-export</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>{{$t('globals.export')}} Excel</span>
                                </v-tooltip>
                                <v-tooltip right color="error">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        fab
                                        dark
                                        small
                                        color="error"
                                        large
                                        @click="exportComplaint('PDF')"
                                    >
                                        <v-icon>mdi-file-pdf-box</v-icon>
                                    </v-btn>
                                    </template>
                                    <span>{{$t('globals.export')}} PDF</span>
                                </v-tooltip>
                            </v-speed-dial>
                        </v-container>
                    </v-card-title>
                    <v-card-text v-if="!loading" class="mt-4">                        
                        <v-row>
                            <v-col cols="12" v-if="method == 'update'">
                                <!-- DESKTOP -->
                                <v-container v-if="!success" class="d-none d-sm-block">
                                    <v-row class="d-flex align-center justify-space-between mt-6 mb-4">
                                    <!-- Linha horizontal -->
                                    <div style="position: relative; width: 100%; height: 4px; background-color: transparent;">
                                        <!-- Primeira parte da linha -->
                                        <div
                                        style="position: absolute; top: 0; left: 0; width: 25%; height: 4px; background-color: #ccc;"
                                        ></div>

                                        <!-- Primeiro quadrado -->
                                        <div
                                        style="position: absolute; top: -30px; left: 0; width: 100px; height: 60px; background-color: #ccc; border-radius: 8px; display: flex; align-items: center; justify-content: center; font-size: 12px; color: white; border: 2px solid white; text-align: center;"
                                        >
                                        {{ fields.created_at }}
                                        </div>

                                        <!-- Número entre o primeiro e o segundo quadrado -->
                                        <div
                                        style="position: absolute; z-index: 999; top: -10px; left: 25%; width: 30px; height: 20px; background-color: white; color: #666; font-size: 14px; font-weight: bold; text-align: center; line-height: 20px; border: 0px solid #ccc; border-radius: 50%;"
                                        >
                                        {{ fields.time_first_change }}
                                        </div>

                                        <!-- Segunda parte da linha -->
                                        <div
                                        style="position: absolute; top: 0; left: calc(25% + 20px); width: calc(50% - 20px); height: 4px; background-color: #ccc;"
                                        ></div>

                                        <!-- Segundo quadrado -->
                                        <div
                                        :style="{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            width: '100px',
                                            height: '60px',
                                            backgroundColor: checkMiddleSquareColor,
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '12px',
                                            color: 'white',
                                            border: '2px solid white',
                                            textAlign: 'center',
                                        }"
                                        >
                                        {{ fields.time_first_change == null ? '' : addDaysToDate(fields.created_at, fields.time_first_change) }}
                                        </div>

                                        <!-- Número entre o segundo e o terceiro quadrado -->
                                        <div
                                        style="position: absolute; top: -10px; z-index: 999;  left: 75%; width: 30px; height: 20px; background-color: white; color: #666; font-size: 14px; font-weight: bold; text-align: center; line-height: 20px; border: 0px solid #ccc; border-radius: 50%;"
                                        >
                                        {{ fields.time_closed }}
                                        </div>

                                        <!-- Terceira parte da linha -->
                                        <div
                                        style="position: absolute; top: 0; left: calc(75% + 20px); width: calc(25% - 20px); height: 4px; background-color: #ccc;"
                                        ></div>

                                        <!-- Terceiro quadrado -->
                                        <div
                                        :style="{
                                            position: 'absolute',
                                            top: '-30px',
                                            right: '0',
                                            width: '100px',
                                            height: '60px',
                                            backgroundColor: checkFinalSquareColor,
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '12px',
                                            color: 'white',
                                            border: '2px solid white',
                                            textAlign: 'center',
                                        }"
                                        >
                                        {{ fields.time_closed == null ? '' : addDaysToDate(fields.created_at, fields.time_closed) }}
                                        </div>
                                    </div>
                                    </v-row>
                                </v-container>
                                <!-- DESKTOP -->
                                <!-- MOBILE  -->
                                <v-container v-if="!success" class="d-sm-none timeline-mobile">
                                    <!-- Primeiro quadrado -->
                                    <div class="timeline-box">
                                        {{ fields.created_at }}
                                    </div>

                                    <!-- Linha entre o primeiro e o segundo quadrado -->
                                    <div class="timeline-connector"></div>

                                    <!-- Segundo quadrado -->
                                    <div class="timeline-box" :style="{ backgroundColor: checkMiddleSquareColor }">
                                        {{ fields.time_first_change == null ? '' : addDaysToDate(fields.created_at, fields.time_first_change) }}
                                    </div>

                                    <!-- Linha entre o segundo e o terceiro quadrado -->
                                    <div class="timeline-connector"></div>

                                    <!-- Terceiro quadrado -->
                                    <div class="timeline-box" :style="{ backgroundColor: checkFinalSquareColor }">
                                        {{ fields.time_closed == null ? '' : addDaysToDate(fields.created_at, fields.time_closed) }}
                                    </div>
                                </v-container>

                                <!-- MOBILE  -->
                            </v-col>



                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.reference')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.reference}}
                            </v-col>

                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.date')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.created_at}}
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.title')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                {{fields.title}}
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.description')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <span v-if="fields.description != 'null'">{{fields.description}}</span>
                                <div v-else>
                                    <!-- Mantém apenas o elemento de áudio sem os botões -->
                                    <audio ref="audioPlayer" controls :src="getSrc(fields)" @ended="audioEnded"></audio>
                                </div>
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.associate')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    dense outlined
                                    v-model="fields.relationship"
                                    :items="getRelations"
                                    :item-text="item => item.reference"
                                    item-value="id"
                                    menu-props="auto"
                                    :label="$t('globals.complaints')"
                                    prepend-inner-icon="mdi-file"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.category')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    dense outlined
                                    v-model="fields.category_id"
                                    :items="categories"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Categoria"
                                    prepend-inner-icon="mdi-shape"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.status')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    dense outlined
                                    v-model="fields.state_id"
                                    :items="states"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Estado"
                                    prepend-inner-icon="mdi-state-machine"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.department')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.department_id"
                                    dense outlined
                                    :items="departments"
                                    :item-text="item => item.i18n[$i18n.locale]"
                                    item-value="id"
                                    menu-props="auto"
                                    :label="$t('globals.department')"
                                    prepend-inner-icon="mdi-home-group"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            
                            <v-col cols="12" md="4" v-if="false">
                                {{$t('globals.severity')}}
                            </v-col>
                            <v-col cols="12" md="8" v-if="false">
                                <v-select
                                    v-model="fields.severity_id"
                                    dense outlined
                                    :items="severities"
                                    :item-text="item => item.level"
                                    item-value="id"
                                    menu-props="auto"
                                    label="Severity"
                                    prepend-inner-icon="mdi-account"
                                    clearable
                                ></v-select>  
                            </v-col>
                            
                            
                            <v-col cols="12" md="4" class="font-weight-bold">
                                {{$t('globals.responsibles')}}
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-select
                                    v-model="fields.users"
                                    :items="users"
                                    :label="$t('globals.responsibles')"
                                    dense outlined
                                    :item-text="item => item.name"
                                    prepend-inner-icon="mdi-account-multiple"
                                    :item-value="item => item.id"
                                    multiple
                                    chips
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-title v-if="fields.confidential" class="primary white--text">
                        {{$t('complaints.whistleblower_data')}}
                    </v-card-title>
                    <v-card-text v-if="fields.confidential" class="mt-4">
                        <v-row >
                            <v-col cols="12" md="1" class="font-weight-bold">
                                {{$t('globals.name')}}:
                            </v-col>
                            <v-col cols="12" md="3">
                                {{fields.person.name}}
                            </v-col>
                            <v-col cols="12" md="1" class="font-weight-bold">
                                {{$t('globals.email')}}:
                            </v-col>
                            <v-col cols="12" md="3">
                                {{fields.person.email}}
                            </v-col>
                            <v-col cols="12" md="2" class="font-weight-bold">
                                {{$t('globals.contact')}}:
                            </v-col>
                            <v-col cols="12" md="2">
                                {{fields.person.telephone}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-title class="primary white--text" v-if="fields.files.length > 0">
                        {{$t('globals.files')}} 
                    </v-card-title>
                    <v-card-text v-if="fields.files.length > 0">
                        <v-row v-for="item in fields.files" :key="item">
                            <v-col cols="12" md="4">
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-btn @click="downloadFile(item)" icon class="success">
                                    <v-icon color="white">mdi-download-box</v-icon>
                                </v-btn>
                                {{item}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions v-if="!disableForm">                        
                        <v-row class="mt-6 mb-5" align="center" justify="space-around">
                            <v-btn :disabled="invalid" depressed color="primary" type="submit">
                                {{$t('globals.save')}}
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                    <v-expansion-panels>
                        <v-expansion-panel
                        >
                        <v-expansion-panel-header class="primary white--text text-h6 mt-4 elevation-4">
                            <template v-slot:actions>
                                <v-icon color="white">
                                $expand
                                </v-icon>
                            </template>
                            {{$t('globals.comments')}} ({{complaint.comments.length}})
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="elevation-4">
                            <v-card-text>
                                <v-row v-for="comment in complaint.comments" :key="comment.id">
                                    <v-col cols="12" md="12">
                                        <div style="position: relative;">
                                            <v-avatar
                                                v-if="comment.user == null"
                                                class="orange darken-2 white--text text-h6"
                                                size="56"
                                            >
                                                WB
                                            </v-avatar>
                                            <v-avatar
                                                v-else
                                                :class="comment.internal ? 'grey white--text text-h6' : 'primary white--text text-h6'"
                                                size="56"
                                            >
                                                {{comment.user.initials}}
                                            </v-avatar>
                                            <span v-if="comment.internal" class="ml-2 text-caption" style="position: absolute; bottom: 0;">
                                                ({{$t('complaints.internal_note')}})    <v-icon small>mdi-lock</v-icon>
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        {{comment.message}}
                                    </v-col>   
                                    <v-col cols="12" style="position:relative;" align="end">   
                                        <span class="text-caption d-none d-lg-block" >
                                            {{comment.user == null ? (complaint.anonymous ? 'Anonymous' : complaint.person.name) : comment.user.name}} {{$t('globals.at')}} {{comment.created_at}}
                                            <div v-if="comment.internal == false && comment.user_id != null">
                                                <br/>
                                                <strong>{{comment.seen ? $t('globals.saw_whistleblower') : $t('globals.not_seen')}}</strong>
                                            </div>
                                        </span>
                                        <span class="text-caption d-sm-none">
                                            {{comment.user == null ? (complaint.anonymous ? 'Anonymous' : complaint.person.name) : comment.user.name}}
                                            <br/>
                                            {{$t('globals.at')}} {{comment.created_at}}
                                            <div v-if="comment.internal == false && comment.user_id != null">
                                                <br/>
                                                <strong>{{comment.seen ? $t('globals.saw_whistleblower') : $t('globals.not_seen')}}</strong>
                                            </div>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="comment.files.length > 0">
                                        <v-expansion-panels>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header class="primary white--text">
                                                    <template v-slot:actions>
                                                        <v-icon class="icon" color="white">$expand</v-icon>
                                                    </template>
                                                    {{$t('globals.documents')}} ({{comment.files.length}})
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-col cols="12" md="12" v-for="f in comment.files" :key="f">
                                                        <v-btn @click="downloadCommentFile(f, comment.id)" icon class="success mr-2">
                                                            <v-icon color="white">mdi-download-box</v-icon>
                                                        </v-btn>
                                                        <span class="text-caption">{{f}}</span>
                                                    </v-col>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>                           
                                    <v-col cols="12">                                
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                                <v-row v-if="canComment" no-gutters>
                                    <v-col cols="12" md="auto">
                                        <v-checkbox
                                            v-model="comment.internal"
                                            :label="$t('globals.internal_note')"
                                            color="error"
                                            inset
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea 
                                            filled
                                            :label="$t('globals.insert_comment')"
                                            v-model="comment.message"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="canComment">
                                    <v-col cols="12" md="6">
                                        <v-file-input
                                            multiple
                                            dense outlined
                                            v-model="comment.files"
                                            :label="$t('globals.attach_files')"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-btn class="primary" @click="initiateAddComment">{{$t('globals.comment')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    
                </v-card>
            </v-container>
            <ChangeState v-if="changeState" :states="states" :actualState="fields.state_id" @add-comment="addComment"/>
            <DialogSuccess :opened="success" @on-ok="onSuccess" >
                {{$t('globals.success_action')}}
            </DialogSuccess>
            <DialogConfirmation 
                v-if="closeComplaintModal" 
                title="Fechar denúncia" 
                type="info" 
                :opened="closeComplaintModal" 
                :yesBTN="$t('globals.proceed')"
                :noBTN="$t('globals.back')"
                @on-submit="submitWhenSure" 
                @on-cancel="closeComplaintModal = false">
                    {{$t('complaints.close_complaint')}}
            </DialogConfirmation>
        </v-form>
    </validation-observer> 
</template>
<script>
import States from '@/api/State.js'
import User from '@/api/ClientUsers.js'
import Category from '@/api/Category.js'
import Department from '@/api/Department.js'
import Severities from '@/api/Severities.js'
import Complaints from '@/api/Complaints.js'
import Configuration from '@/api/Configuration.js'
import Comment from '@/api/Comment.js'
import DialogSuccess from '@/components/ui/DialogSuccess'
import ChangeState from '@/components/complaints/dialogs/ChangeState'
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';

export default{
    props:{
        complaint: Object,
        method: String
    },
    components:{
        DialogSuccess,
        ChangeState,
        DialogConfirmation
    },
    data(){
        return{
            closeComplaintModal: false,
            changeState: false,
            speedDial: false,
            success: false,
            loading: true,
            states: [],
            categories: [],
            departments: [],
            severities: [],
            relations: [],
            users: [],
            fields: null,
            configurations: [],
            addCommentHelper: null,
            comment: {
                message: null,
                files: null,
                seen: false,
                user_id: null,
                complaint_id: null,
                internal: false
            },
            playingAudio: null, // Para armazenar o áudio que está sendo reproduzido
        }
    },
    mounted(){
        this.fields = this.complaint
        this.fillBaseData();
    },
    computed:{
        checkFinalSquareColor(){
            if(this.configurations.length == 0)
                return '#ccc';
                
            if(this.fields.time_closed == null)
                return '#ccc';
                
            let final_time = null;
            this.configurations.forEach(element => {
                if(element.key == 'deadline_for_investigation'){
                    final_time = parseInt(element.value)
                }
            });

            if(final_time >= this.fields.time_closed)
                return '#4CAF50';
            

            return '#FF5252';
        },
        checkMiddleSquareColor(){
            if(this.configurations.length == 0)
                return '#ccc';

            if(this.fields.time_first_change == null)
                return '#ccc';
                
            let middle_time = null;
            this.configurations.forEach(element => {
                if(element.key == 'deadline_after_receipt'){
                    middle_time = parseInt(element.value)
                }
            });

            if(middle_time >= this.fields.time_first_change)
                return '#4CAF50';
            

            return '#FF5252';
        },
        // Apenas responsáveis podem editar a denúncia
        canComment(){
            let flag = false
            let user_id = this.$root.session.id
            this.fields.users.forEach(element => {
                if(element == user_id)
                    flag = true
            });
            return flag
        },
        disableForm(){
            if(this.fields.state.i18n['pt'] == 'Fechado')
                return true

            
            return false
        },
        getRelations(){
            let returner = [];

            this.relations.forEach(element => {
                if(element.reference != this.fields.reference)
                    returner.push(element)
            });

            return returner
        }
    },
    methods:{
        isToClose(validacao){
            let closed_id = null
            this.states.forEach(element => {
                if(element.reference == '005')
                    closed_id = element.id
            });

            if(validacao == closed_id)
                return true

            return false
        },
        addDaysToDate(date, days) {
            const baseDate = new Date(date);
            baseDate.setDate(baseDate.getDate() + parseInt(days, 10)); // Adiciona os dias
            return baseDate.toISOString().split('T')[0]; // Formata como 'YYYY-MM-DD'
        },
        getSrc(item){
            return process.env.VUE_APP_API_URL + this.$route.params.tenant + '/3Jk9lRf2Qb8wTg5Yh1pEwA3uZ/N7xXb2QpA4k8Yw1Ff2jLqV5mZ/T8n6Hc4RjQ1eWq9Fk5ZpJ8yXh/' + item.id
        },
        audioEnded() {
            this.playingAudio = null; // Reseta o estado quando o áudio termina
        },
        exportComplaint(type = 'Excel'){
            let data = {
                locale: this.$i18n.locale,
                type: type,
            }
            
            Complaints.export(this.fields.id, data).then((resp) => {
                if(resp.data != null){
                    window.open(resp.data, '_blank')
                }
            });
        },
        onSuccess(){
            this.$emit('reload')
        },
        initiateAddComment(){
            this.changeState = true;
        },
        addComment(state_id){
            if(this.isToClose(state_id) && this.addCommentHelper == null){
                this.closeComplaintModal = true
                this.addCommentHelper = state_id;
                return
            }

            this.addCommentHelper = null;

            this.comment.user_id = this.$root.session.id
            this.comment.complaint_id = this.$route.params.id

            const formData = new FormData();
            formData.append('state_id', state_id);
            formData.append('message', this.comment.message)
            formData.append('user_id', this.comment.user_id)
            formData.append('internal', this.comment.internal)
            formData.append('complaint_id', this.comment.complaint_id)
            if(this.comment.files != null){
                for (var i = 0; i < this.comment.files.length; i++ ){
                    let file = this.comment.files[i];
                    formData.append('files[]', file);
                }
            }

            Comment['create'](formData).then(() => {
                this.success = true
                this.loading = false
            }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
            });
        },
        submitWhenSure(){
            if(this.addCommentHelper != null){
                this.addComment(this.addCommentHelper)
                return
            }
            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }

                this.closeComplaintModal = false
        
                let data = {...this.fields};
        
                Complaints['update'](data, this.fields.id).then(() => {
        
                    this.success = true;
        
                    this.loading = false;
                    
                }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
                    });
            })
        },
        submit(){                
            if(this.isToClose(this.fields.state_id)){
                this.closeComplaintModal = true
                return
            }

            this.submitWhenSure()
            
        },
        downloadCommentFile(file, id){
            Comment.downloadFile(file, id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        downloadFile(file){
            Complaints.downloadFile(file, this.complaint.id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        async fillBaseData(){
            States.list().then(({data}) => {
                this.states = data;
            });
            Category.list().then(({data}) => {
                this.categories = data;
            });
            Department.list().then(({data}) => {
                this.departments = data;
            });
            Severities.list().then(({data}) => {
                this.severities = data;
            });

            User.listComplaintResponsible().then(({data}) => {
                this.users = data.filter(item => item.permissions.some(permission => permission.name == "complaints.manager"));
            });
            Complaints.getRelations().then(({data}) => {
                this.relations = data
            });

            Configuration.list(null, this.$session.get('domain')).then((resp) =>  {
                this.configurations = resp.data
            })

            this.loading = false
        }
    }
}
</script>
<style>
.timeline-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-box {
  width: 100px;
  height: 60px;
  margin: 10px 0;
  background-color: #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  border: 2px solid white;
  text-align: center;
  position: relative;
  z-index: 1; /* Certifica-se de que o quadrado está acima das linhas */
}

.timeline-connector {
  width: 2px;
  height: 20px; /* Altura da linha entre os quadrados */
  background-color: #ccc; /* Cor da linha */
  margin: 0 auto; /* Centraliza a linha */
  position: relative;
  z-index: 0;
}

</style>