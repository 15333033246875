<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-shield-account</v-icon> <span>{{$t('department.edit_crumb')}}</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <department v-else method="update" :department="department"></department>
        </div>
    </section>
  </template>
  <script>
  import Department from '@/components/departments/Department'
  import DepartmentAPI from "@/api/Department.js";
  
  export default {
    components: {
        Department
    },
    data: () => ({
        department: {},
        loading:true
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "departments.update"])) {
        this.$router.push('/' + this.$session.get('domain') + '/no-permission');
        return
      }
  
      DepartmentAPI.find(this.$route.params.id).then(({data}) => { this.department = data; this.loading = false;});
    },
    methods:{
      
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: this.$t('menu.dashboard'),
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: this.$t('menu.definitions'),
            disabled: false,
            to: '/' + this.$session.get('domain') + '/admin/settings/departments',
            exact: true,
          },
          {
            text: this.$t('department.edit_crumb'),
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  